<script setup>
import { onMounted } from "vue";

defineProps({
  blok: Object,
  parentBlok: Object,
});

const logos = [
  { src: "/Kundenlogos/Evonik_Referenz_Logo.png", class: "evonik" },
  { src: "/Kundenlogos/novartis.png", class: "novartis" },
  { src: "/Kundenlogos/roche.png", class: "roche" },
  { src: "/Kundenlogos/Currenta_Referenz_Logo.png", class: "currenta" },
  { src: "/Kundenlogos/Referenz_Pharma_1.png", class: "genetech" },
  { src: "/Kundenlogos/gedion-richter.png", class: "gedion" },
  { src: "/Kundenlogos/Greiner_Logo_Referenz.png", class: "greiner" },
];

// Funktion zum Duplizieren des Inhalts von .images
const duplicateImagesContent = () => {
  const clientsGrid = document.querySelector(".slideshow-wrap");

  if (clientsGrid) {
    const clientElements = clientsGrid.querySelectorAll(".client-grid-content");

    clientElements.forEach((clientElement) => {
      const clonedNode = clientElement.cloneNode(true);
      clientsGrid.appendChild(clonedNode);
    });
  }
};

onMounted(() => {
  duplicateImagesContent();
});
</script>

<template>
  <div
    v-editable="blok"
    :class="{ 'hero-clients-wrap': parentBlok?.component === 'main-hero' }"
  >
    <div class="clients-wrap">
      <div class="clients-loop slideshow">
        <div class="clients-loop-content images">
          <div class="slideshow-wrap d-flex">
            <div
              v-for="(logo, i) in logos"
              :key="i"
              class="client-grid-content"
            >
              <div class="client-logo-wrap">
                <img
                  :src="logo.src"
                  loading="lazy"
                  alt=""
                  class="client-logo client-list-logo white-logo"
                  :class="[logo?.class]"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.client-grid-content {
  margin: auto 30px;
  min-width: 120px;
}

.client-logo {
  zoom: 1.2;
}
.slideshow {
  overflow: hidden;
  position: relative;
}

.clients-loop-content {
  display: flex;
  animation: slide-left 50s linear infinite;
}

@keyframes slide-left {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}
</style>
